.custom-select {
  display: block;
  position: relative;
  border: 1px solid $lightest-grey;
  border-radius: 4px;
  background-color: $lightest-grey;

  &::after {
    position: absolute;
    top: 16px;
    right: 16px;
    transform: rotate(90deg);
    background: url('/assets/icons/misc/arrow-big.svg') no-repeat;
    width: 8px;
    height: 12px;
    content: '';
  }

  select {
    appearance: none;
    display: block;
    margin: 0;
    outline: none;
    border: 0;
    border-radius: 0;
    background: transparent;
    padding: 0 16px;
    width: 100%;
    height: 40px;
    line-height: 40px !important;
    color: $darkest-grey;
    font: var(--paragraph-font);

    &:required:invalid {
      color: $dark-grey;
    }

    option[value=''][disabled] {
      display: none;
    }
  }

  &--small {
    border-radius: 20px;
    border-color: $grey;
    background-color: #fff;
    min-width: 72px;
    height: 32px;

    &::after {
      top: 12px;
      background: url('/assets/icons/misc/arrow-small.svg') no-repeat;
    }

    select {
      height: 32px;
      line-height: 32px !important;
    }
  }
}
