/*====================
TEXT INPUT / AREA
====================*/
input,
input[type],
textarea {
  appearance: none;
  flex: 1;
  transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out, opacity 300ms ease-in-out;
  border: 1px solid $lightest-grey;
  border-radius: 4px;
  background-color: $lightest-grey;
  width: 100%;
  color: $darkest-grey;
  font: var(--paragraph-font);
  font-size: 1.6rem;

  &::placeholder {
    color: $dark-grey;
  }

  &::-ms-clear {
    display: none;
  }

  &:focus {
    border-color: $grey;
    background-color: #fff;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:read-only {
    &:focus {
      outline: none;
      border-color: $lightest-grey;
      background-color: $lightest-grey;
    }

    &:hover {
      cursor: default;
    }
  }

  &.inverted-input {
    border-color: #fff;
    background-color: #fff;

    &:focus {
      border-color: $lightest-grey;
      background-color: $lightest-grey;
    }
  }
}

input,
input[type] {
  padding: 0 16px;
  height: 40px;
  line-height: 40px !important;
}

textarea {
  // line-height: 24px !important;
  padding: 12px 16px;
  resize: none;
}

// input groups for including labels / icons etc. over the input
.input-group {
  display: flex;
  position: relative;
  flex: 1;
  align-items: center;
  margin-bottom: 16px;
  height: 40px;

  &:last-of-type {
    margin-bottom: 0;
  }

  input,
  input[type],
  textarea {
    flex: auto;
  }

  &--disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  input {
    width: 100%;
  }

  label {
    color: $darkest-grey;
    font: var(--paragraph-font);
  }

  &__icon,
  &__label,
  &__btn {
    display: flex;
    position: absolute;
    left: 16px;
    margin: 0 !important;

    &--end {
      right: 16px;
      left: auto;
    }
  }

  &--price {
    label {
      position: absolute;
      left: 16px;
    }

    input {
      padding-left: 30px;
      color: $secondary;
    }

    &--featured {
      height: 64px;

      label {
        font: var(--h2-font);
      }

      input {
        padding-left: 40px;
        height: 64px;
        line-height: 64px !important;
        font: var(--h2-font);
      }
    }
  }

  &--icon-start input,
  &--btn-start input,
  &--label-start input {
    padding-left: 42px;
  }

  &--icon-end input,
  &--icon-end textarea,
  &--btn-end input,
  &--btn-end textarea,
  &--label-end input,
  &--label-end textarea {
    padding-right: 42px;
  }

  &--large {
    height: 64px;

    input,
    input[type] {
      height: 64px;
      font: var(--h3-font);
    }

    .input-group__label {
      font: var(--h3-font);
    }
  }
}
