/*====================
overwrite styles on
ngx-cookieconsent
====================*/

.cc-window {
  border-radius: 4px;
  background-color: $lightest-grey;
  z-index: 999999;

  .cc-message {
    color: $darkest-grey;
  }

  .cc-link {
    padding: 0;
    text-decoration: none;

    // font: $fs-14 $font-semi-bold;
  }

  .cc-btn {
    display: flex !important;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    border: 0;
    border-radius: 20px;
    background-color: $red;
    cursor: pointer;
    padding-top: 8px;
    width: auto;
    height: 40px;
    color: #fff;

    // font: $fs-16 $font-semi-bold;

    &:hover {
      background-color: $primary-dark;

      &:disabled {
        background-color: $red;
      }
    }
  }
}
