// /*====================
// ITEM GRID VIEW ONLY
// ====================*/

// .item--item {
//   .item__image .save-item {
//     position: absolute;
//     right: 8px;
//     bottom: 8px;
//     width: 32px;
//     height: 32px;
//   }

//   .item__info .item-meta__price {
//     text-transform: uppercase;
//     color: $blue;
//   }
// }

.item__image .item-tag {
  position: absolute;
  top: 8px;
  right: 8px;
}
