/*====================
TYPOGRAPHY
====================*/

/*====================
todo: text letter casing
====================*/

/* h1,
h2,
h3,
h4,
li p,
li a,
p,
a {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

.icr {
  text-transform: none !important;

  &::first-letter {
    text-transform: none !important;
  }
} */

.enforce-case-rule {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

/*====================

====================*/

// headings
h1,
h2,
h3,
h4 {
  color: $darkest-grey;
}

h1 {
  font: var(--h1-font);
}

h2 {
  font: var(--h2-font) !important;
}

h3 {
  font: var(--h3-font) !important;
}

// body text & paragraphs
p {
  word-break: break-word;
  color: $dark-grey;
  font: var(--paragraph-font);

  a {
    font-size: inherit;
  }
}

.p-large {
  font: var(--paragraph-large-font);
}

.p-small {
  font: var(--paragraph-small-font);
}

.sub-text {
  font: var(--sub-text-font);
}

.colour-primary,
.colour-secondary {
  word-break: initial;
  font-family: var(--font-regular);
}

.colour-primary {
  color: $primary !important;
}

.colour-secondary {
  color: $secondary !important;
}

// feed specific
.feed-item-name {
  color: $darkest-grey;
  font: var(--feed-item-name);
}

.feed-item-description {
  font: var(--feed-item-description);
}

.feed-item-price {
  font: var(--feed-item-price);
}

// tags
.tag {
  color: #fff;
  font: var(--tag-font);

  &--big {
    font: var(--tag-big-font);
  }
}

// text links
a {
  transition: color 300ms ease-in-out;
  cursor: pointer;
  text-decoration: none;
  line-height: var(--text-link-font-line-height);
  color: $darkest-grey;
  font-family: var(--text-link-font-family) !important;
  font-size: var(--text-link-font-size);

  &:hover {
    color: $primary;
  }

  &.a-red {
    color: $red;

    &:hover {
      color: $red-dark;
    }
  }

  &.a-disabled {
    cursor: not-allowed;
    color: $light-grey;

    &:hover {
      color: $light-grey;
    }
  }

  &.a-grey {
    color: $dark-grey;

    &:hover {
      color: $primary;
    }
  }

  &.a-blue {
    color: $secondary;

    &:hover {
      color: $secondary-dark;
    }
  }
}
