.notifier__notification {
  justify-content: space-between;
  z-index: 999999999;
  box-shadow: 0 2px 4px rgba($dark-grey, 0.3);
  padding: 16px 16px 14px;

  &--default {
    background-color: $lightest-grey;
  }

  &--error {
    background-color: $primary-dark;
  }

  &--success {
    background-color: $green;
  }

  &--warning {
    background-color: $gold;
  }

  .notifier__notification-message {
    padding-right: 8px;
    line-height: 1.6rem;
    color: $darkest-grey;
    font-size: 1.6rem;
  }

  .notifier__notification-button {
    transition: opacity 0.2s ease;
    opacity: 0.75;
    background: none;
    padding: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    margin: 0;
    padding: 0;
    min-width: 24px;

    &:hover {
      opacity: 1;
    }

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: $darkest-grey;
      }
    }
  }
}
