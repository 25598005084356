/*====================
STEPS LAYOUT
====================*/

/*-- general --*/
.steps-layout {
  // display: grid;
  // grid-template-rows: auto 1fr;
  height: 100%;

  &__component-container {
    height: 100%;
  }

  &__columns {
    display: grid;
    padding: 40px var(--grid-container-padding) 0;
    overflow-y: scroll;

    &__left,
    &__right {
      display: none;
    }

    &__middle {
      display: grid;
      grid-template-rows: 1fr 72px;

      /* Allows content to fill the viewport and go beyond the bottom */
      height: 100%;

      /* Allows you to scroll below the viewport; default value is visible */
      // overflow-y: scroll;

      /* To smooth any scrolling behavior */
      -webkit-overflow-scrolling: touch;

      &__footer {
        @include e2e;
        display: flex;
        position: sticky;
        bottom: 0;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        border-top: 1px solid $lightest-grey;
        box-shadow: 0 -3px 12px rgba(68, 65, 65, 0.2);
        background: #fff;
        padding: 16px 24px;

        button {
          margin: 0;
        }
      }
    }

    &__right {
    }

    &--review-screen .steps-layout__columns__middle {
      grid-template-rows: 1fr 146px;
    }
  }
}

@include tablet {
  .steps-layout__columns {
    &__middle__footer {
      margin: 0 calc(80px * -1);
      padding: 16px 80px;
    }

    &--2-column {
      padding-right: 80px;
      padding-left: 80px;
    }

    .tip {
      right: 80px;
      left: 80px;
    }
  }
}

@include desktop {
  .steps-layout {
    display: grid;

    // grid-template-rows: 1fr auto;
    // minmax is needed because safari doesn't work with just 1fr for some f'ing reason
    grid-template-rows: minmax(0, 1fr) auto;

    &__component-container {
      overflow: hidden;
    }

    &__columns {
      grid-template-columns: auto 1fr minmax(auto, 308px);
      padding-top: 24px;
      padding-bottom: 24px;
      height: calc(100% - 80px) !important;

      &__left,
      &__right {
        display: block;
      }

      &__left {
        margin: 0 40px 0 0;
        padding: 0;
      }

      &__middle {
        display: block;
        position: relative;
        margin-right: 16px;
        padding-right: 108px;
        padding-left: 16px;
        overflow: scroll;
        overflow: visible;

        &__footer {
          margin: 0;
          border: 0;
          box-shadow: none;
          padding-right: 0;
          padding-right: 16px;
          max-width: 756px;
        }
      }

      &--2-column {
        grid-template-columns: auto 1fr;
        padding-right: var(--grid-container-padding);
        padding-left: var(--grid-container-padding);

        .steps-layout__columns__middle {
          margin-right: 0;
          padding-right: 0;
          overflow: scroll;
        }

        .tip {
          position: initial;
          margin: 0 12px 0 28px;
        }
      }
    }

    .step-section {
      transition: opacity 300ms ease-in-out;

      &--disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }
}
