/*====================
MENU
we use angular material for our menus
so we need to overwrite their styles
====================*/

pc-menu {
  // display: block;
  // min-height: 32px;
  // max-height: 40px;
  display: flex;
}

.ng-content-ref {
  display: inherit;
  position: inherit;
  align-items: inherit;
  justify-content: inherit;
  width: inherit;
  height: inherit;
}

.mat-menu-panel {
  margin-top: 8px;
  border-radius: 4px;
  box-shadow: 0 3px 12px rgba($darkest-grey, 0.2);
  background-color: #fff;
  max-width: none !important;
  min-height: 0 !important;

  .mat-menu-content {
    padding: 0 !important;
  }
}

.mat-menu-panel.edit-menu {
  min-width: 280px;
  max-width: 340px;

  .mat-menu-content {
    display: flex;
    flex-direction: column;

    .menu-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 16px -16px;
      background-color: $lightest-grey;
      padding: 16px;
    }

    .menu-list {
      padding: 0 16px;

      li {
        display: flex;
        justify-content: center;
        border-bottom: 1px solid $lightest-grey;
        padding: 0 8px;

        &:last-of-type {
          border-bottom: 0 none;

          .nested {
            border-bottom: 1px solid $lightest-grey;
          }
        }

        a,
        button {
          display: block;
          padding: 16px 0;
          width: 100%;
          text-transform: lowercase;
          font-size: 1.8rem;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

/*====================
SPECIFIC USE CASES
====================*/
.search-filter-dropdown,
.filter-list-menu {
  margin-top: 16px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 24px;
  width: 416px;
}

.menu-button {
  margin: 0;
}

/*-- ngx-phone-number --*/
.phone-number-menu {
  display: flex;
  margin-top: 16px;
  width: 350px;
  height: 200px;

  li {
    display: flex;
    align-items: center;
    transition: background-color 0.2s ease-in-out;
    margin-right: -8px;
    margin-left: -8px;
    border-radius: 4px;
    cursor: pointer;
    padding: 16px 8px;

    &:first-of-type {
      margin-top: -8px;
    }

    &:last-of-type {
      margin-bottom: -8px;
    }

    &:hover {
      background-color: #f2f1f1;
    }

    .flag {
      margin-right: 8px;
    }
  }
}

.mat-menu-panel {
  &.header-notifications-messages-menu {
    padding: 16px 16px 24px;
    width: 340px;
    max-width: none;
  }
}

.filter-list-menu {
  h2 {
    margin-bottom: 24px;
  }
}
