/*====================
edge-to-edge
force content to the edge
of the screen
====================*/
@mixin e2e {
  margin-right: calc(var(--grid-container-padding) * -1);
  margin-left: calc(var(--grid-container-padding) * -1);
}

@mixin e2e-absolute {
  right: calc(var(--grid-container-padding) * -1);
  left: calc(var(--grid-container-padding) * -1);
}
