/*====================
GRID
====================*/
// variables
:root {
  --grid-columns: 2;
  --grid-column-width: 163px;
  --grid-column-gutter: 16px;
  --grid-container-tb-padding: 8px;
  --grid-container-padding: 16px;
  --grid-container-max-width: 749px;
  --grid-row-tb-padding: 16px;
  --grid-row-tb-margin: 24px;
}

@include tablet {
  :root {
    --grid-columns: 6;
    --grid-column-width: 106px;
    --grid-container-padding: 26px;
    --grid-container-max-width: 1199px;
  }
}

@include desktop {
  :root {
    --grid-columns: 12;
    --grid-column-width: 92px;
    --grid-container-tb-padding: 16px;
    --grid-container-padding: 80px;
    --grid-container-max-width: 1440px;
  }
}

// grid
.grid-container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--grid-container-padding);
  padding-left: var(--grid-container-padding);
  width: 100%;
  max-width: var(--grid-container-max-width);
  height: 100%;

  &--tb-padding {
    padding-top: var(--grid-container-tb-padding);
    padding-bottom: var(--grid-container-tb-padding);
  }
}

section,
.grid-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: var(--grid-row-tb-margin) 0;
  padding: var(--grid-row-tb-padding) 0;

  &--no-mp {
    margin: 0;
    padding: 0;
  }

  &--no-top-margin {
    margin-top: 0;
  }

  &--no-bottom-margin {
    margin-bottom: 0;
  }

  &--no-tb-margin {
    margin-top: 0;
    margin-bottom: 0;
  }

  &--no-margin {
    margin: 0;
  }

  &--no-padding {
    padding: 0;
  }

  &--no-top-padding {
    padding-top: 0;
  }

  &--no-bottom-padding {
    padding-bottom: 0;
  }

  &--no-tb-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  &--flex-end {
    align-items: flex-end;

    button:last-of-type {
      margin-right: 0;
    }
  }
}

.grid-col {
  flex-basis: 0;
  flex-grow: 1;
  margin-right: var(--grid-column-gutter);
  width: 100%;
  max-width: 100%;

  &:last-of-type {
    margin-right: 0;
  }
}

@for $colNum from 1 through 2 {
  .grid-col-#{$colNum} {
    max-width: calc(calc(var(--grid-column-width) * #{$colNum}) + calc(var(--grid-column-gutter) * #{$colNum - 1}));

    // min-width: calc(calc(var(--grid-column-width) * #{$colNum}) + calc(var(--grid-column-gutter) * #{$colNum - 1}));
  }
}

@for $colNum from 3 through 12 {
  .grid-col-#{$colNum} {
    min-width: 342px;
    max-width: 342px;
  }
}

@include tablet {
  .grid-row--tablet-extra {
    padding-right: 54px;
    padding-left: 54px;
  }

  @for $colNum from 3 through 6 {
    .grid-col-#{$colNum} {
      max-width: calc(calc(var(--grid-column-width) * #{$colNum}) + calc(var(--grid-column-gutter) * #{$colNum - 1}));

      // min-width: calc(calc(var(--grid-column-width) * #{$colNum}) + calc(var(--grid-column-gutter) * #{$colNum - 1}));
    }
  }

  @for $colNum from 7 through 12 {
    .grid-col-#{$colNum} {
      min-width: 716px;
      max-width: 716px;
    }
  }
}

@include desktop {
  .grid-row--tablet-extra {
    padding-right: 0;
    padding-left: 0;
  }

  .grid-container--thin {
    max-width: 1008px;
  }

  @for $colNum from 7 through 12 {
    .grid-col-#{$colNum} {
      max-width: calc(calc(var(--grid-column-width) * #{$colNum}) + calc(var(--grid-column-gutter) * #{$colNum - 1}));

      // min-width: calc(calc(var(--grid-column-width) * #{$colNum}) + calc(var(--grid-column-gutter) * #{$colNum - 1}));
    }
  }
}
