/*====================
imports
====================*/
@import './variables/all-variables';
@import './base/all-base';
@import './layouts/all-layouts';
@import './elements/all-elements';
@import './forms/all-forms';
@import './components/all-components';
@import './libraries/all-libraries';
@import './miscellaneous/all-miscellaneous';
@import './old-needs-refactoring/old';

/*====================
generic
====================*/
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 10px;
}

body {
  background-color: #fff;
  color: $dark-grey;
  font: var(--paragraph-font);

  &.body-grey-background {
    background-color: $background-grey;
  }
}

html,
body {
  height: 100%;
}

.kyc-required a {
  text-decoration: underline;
  color: $primary;
}

.maintenance-banner {
  width: 100vw;
  height: 50px;
  background-color: $lightest-grey;
  position: fixed;
  z-index: 999;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: $darkest-grey;
    text-align: center;
    font-size: 12px;
    line-height: 14px;

    @include tablet {
      font-size: 14px;
      line-height: 16px;
    }

    @include desktop {
      font-size: 16px;
      line-height: 18px;
    }
  }

  + .paperclip {
    pc-site-header header .header-container {
      top: 50px;
    }

    &:not(.email-banner-visible) {
      padding-top: 50px;
    }
  }

  + #paperclip-steps-layout {
    padding-top: 50px;
  }
}
