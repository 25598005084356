@import 'colours', 'breakpoints';

.grid-view-new-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(126px, 1fr));
  margin-bottom: 106px;
  gap: 72px 16px;

  .loading-data {
    pointer-events: none;

    .grid-item__image img {
      display: block;
      background: $lightest-grey;
    }

    .grid-item__tag {
      display: none;
    }

    .grid-item__meta {
      margin: 8px 0 4px;
      background-color: var(--loading-colour);
      padding: 0;
      color: transparent !important;
    }

    .list-item__description {
      height: 6.4rem;
    }

    .social-inventory-btn {
      border-color: $lightest-grey;
      background-color: $lightest-grey;
    }
  }

  &--users {
    margin-bottom: 109px;
    gap: 109px 16px;
  }

  &--social-inventory {
    row-gap: 112px;

    .social-inventory-btn {
      margin: 8px 0 0;
    }
  }

  .grid-item {
    position: relative;
    border-radius: 8px;
    padding-top: 100%;

    &:hover .grid-item__image {
      opacity: 0.75;
    }

    &--no-link:hover .grid-item__image {
      opacity: 1;
    }

    &__favourite,
    &__tag {
      position: absolute;
      right: 8px;
      z-index: 1;
    }

    &__favourite {
      bottom: 8px;
    }

    &__tag {
      top: 8px;
    }

    &__image,
    &__content {
      position: absolute;
      width: 100%;
    }

    &__image {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: all 300ms ease-in-out;

      img {
        border: 1px solid rgba($darkest-grey, 0.05);
        border-radius: 8px;
        background: $lightest-grey;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__meta {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0 4px;

      &__category {
        color: #949191;
        font: var(--feed-item-description);
      }

      &__price {
        font: var(--feed-item-price);
      }
    }

    &__name {
      height: 18px;
      color: $darkest-grey;
      font: var(--feed-item-name);
    }
  }
}

.search-items-form {
  display: flex;
  margin-bottom: 24px;

  .search-input {
    input {
      padding-right: 86px;
    }

    pc-clear-input-btn {
      right: 56px;
    }

    &__search-button {
      display: flex;
      right: 0;
      border: 1px solid $grey;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: #fff;
      width: 40px;
      height: 40px;
    }
  }

  .cancel-search-btn {
    margin: 0 0 0 16px;
    border-color: transparent;
    padding: 0;
    min-width: 0;
    font: var(--paragraph-small-font);
  }
}

@include x-small {
  .grid-view-new-container {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));

    &--users {
      margin-bottom: 109px;
      gap: 109px 16px;
    }
  }
}

@include tablet {
  .grid-view-new-container {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }

  .search-items-form .cancel-search-btn {
    margin: 0 0 0 40px;
    border-color: $grey;
    padding: 0 16px;
    width: 115px;
    min-width: 0;

    &:hover {
      border-color: $lightest-grey;
    }
  }
}

@include desktop {
  .grid-view-new-container {
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    margin-bottom: 124px;
    gap: 76px 16px;

    &--users {
      margin-bottom: 112px;
      gap: 112px 16px;
    }

    &--social-inventory {
      row-gap: 124px;
    }

    .grid-item__name {
      height: 24px;
    }
  }
}
