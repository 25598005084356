.mobile-settings-list {
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__heading,
  .list-link a,
  .list-link__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__heading {
    padding: 16px;
  }

  &__links {
    border-top: 1px solid $lightest-grey;
    border-bottom: 1px solid $lightest-grey;
    background-color: #fff;
    padding: 24px 16px;

    .list-link {
      margin-bottom: 32px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &__right p {
        margin-left: 8px;
        text-align: right;
      }

      img {
        margin-left: 16px;
      }

      a {
        flex: 1;
      }
    }
  }
}
