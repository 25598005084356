/*====================
BUTTONS
====================*/

/*--
  default button style is medium - tertiary in the styleguide
  .a-btn class is for text links that need to look like a button
  - use link buttons for buttons that navigate the user
  - use actual buttons for buttons that perform a function
--*/
button,
.btn {
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  transition: border-color 300ms ease-in-out, background-color 300ms ease-in-out, opacity 300ms ease-in-out,
    color 150ms ease-in-out;

  // margin: 0 16px;
  margin: 0 16px 0 0;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: $lightest-grey;
  cursor: pointer;
  padding: 0 16px;
  width: auto;
  min-width: 92px;
  height: 32px;
  line-height: 32px !important;
  white-space: nowrap;
  color: $darkest-grey;
  font: var(--button-font);
  align-items: center;

  &:hover {
    background-color: $light-grey;
    color: $darkest-grey;
  }

  &:disabled,
  &--disabled {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;

    &:hover {
      background-color: $lightest-grey;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  img,
  svg {
    align-self: center;
  }

  &.btn-loading::after {
    border: 2px solid $darkest-grey;
  }
}

/*-- buttons with icons --*/
.btn-left-icon,
.btn-right-icon {
  img {
    transition: transform 300ms ease-in-out;
  }

  &.flip-icon img {
    transform: rotate(180deg);
  }
}

.btn-left-icon img {
  margin-right: 8px;
}

.btn-right-icon img {
  margin-left: 8px;
}

/*-- size variations --*/
.btn-large,
%btn-large {
  border-radius: 24px;
  height: 40px;
  line-height: 40px !important;
  font: var(--button-large-font);

  &--fit-content {
    flex: 1;
    margin: 0 24px;
    width: 100%;
  }

  &--fluid {
    padding: 0 24px;
  }

  &--fixed {
    width: 163px;
  }

  &--icon {
    padding: 0 24px;
    min-width: 0;

    img {
      margin-left: 16px;
    }
  }

  &.btn-left-icon img {
    margin-right: 16px;
  }

  &.btn-right-icon img {
    margin-left: 16px;
  }
}

.btn-medium--icon img {
  margin-left: 8px;
}

.btn-medium--edit {
  min-width: 64px;
}

.btn-small {
  margin: 0;
  min-width: 0;
  height: 24px;
  line-height: 24px !important;
  font: var(--button-small-font);
}

/*-- styles / colours & states --*/
.btn-stroked,
%btn-stroked {
  border-color: $grey;
  background-color: transparent;

  &:hover {
    border-color: $lightest-grey;
    background-color: $lightest-grey;
  }

  &:disabled:hover,
  &.link-button--disabled:hover {
    background-color: transparent;
  }
}

.btn-opacity {
  background-color: rgba(#fff, 0.9);

  &:hover {
    background-color: rgba($lightest-grey, 0.9);
  }

  &:disabled:hover,
  &.link-button--disabled:hover {
    background-color: rgba(#fff, 0.9);
  }
}

.btn-white {
  border-color: transparent;
  background-color: transparent;

  &:hover {
    border-color: transparent;
    background-color: transparent;
  }
}

// generate colour buttons using sass @each function
$btn-colours: (
  'primary' $primary $primary-dark,
  'secondary' $secondary $secondary-dark,
  'red' $red $red-dark,
  'green' $green $green-dark,
  'dark-grey' $darkest-grey darken($darkest-grey, 10),
  'facebook' $facebook darken($facebook, 10),
  'apple' $apple lighten($apple, 10),
  'twitter' $twitter darken($twitter, 10),
  'messenger' $messenger darken($messenger, 10),
  'instagram' $instagram darken($instagram, 10)
);

@each $name, $colour, $hover-colour in $btn-colours {
  .btn-#{$name} {
    border-color: $colour;
    background-color: $colour;
    color: #fff;
    font-family: var(--font-semi-bold) !important;

    &:hover {
      border-color: $hover-colour;
      background-color: $hover-colour;
      color: #fff;
    }

    &:disabled:hover,
    &.link-button--disabled:hover {
      background-color: $colour;
    }

    &.btn-loading::after {
      border: 2px solid #fff;
    }
  }
}

/*-- icon buttons --*/
.btn-icon,
%btn-icon {
  @extend %btn-stroked;
  position: relative;
  border-radius: 50%;
  padding: 0;
  width: 40px;
  min-width: 40px;
  height: 40px;

  &.btn-white {
    border-color: transparent;
  }

  pc-social-icon {
    display: flex;
  }

  &--small {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }

  &--fill {
    border-color: $lightest-grey;
    background-color: $lightest-grey;

    &:hover {
      border-color: $light-grey;
      background-color: $light-grey;
    }
  }

  &--full {
    border-color: transparent;

    svg,
    img {
      position: absolute;
      top: -1px;
      left: -1px;
    }
  }
}

/*-- specific use-case buttons that violate the above rules --*/
.btn-no-click {
  pointer-events: none;
}

.btn-no-styling,
%btn-no-styling {
  display: flex;
  transition: none;
  margin: 0;
  border: 0;
  border-radius: inherit;
  background-color: transparent;
  padding: 0;
  width: auto;
  min-width: auto;
  height: auto;
  line-height: normal;

  &:hover {
    background-color: transparent;
  }
}

.btn-no-margin-right {
  margin-right: 0;
}

// for accessibility, a button that looks like a link
.btn-link {
  @extend %btn-no-styling;

  // flex: 0 1 0;
  flex: 1;
  justify-content: flex-start;
  transition: color 300ms ease-in-out;
  margin-right: 0;
  width: 100%;
  text-align: left;
  line-height: 2.4rem !important;
  font-family: var(--text-link-font-family);
  font-size: inherit;

  &:hover {
    color: $primary;
  }

  &:disabled:hover {
    background-color: transparent;
    color: $darkest-grey;
  }

  &--red {
    color: $red;

    &:hover {
      color: $red-dark;
    }
  }

  &--primary {
    color: $primary;

    &:hover {
      color: $red;
    }
  }

  &--inline {
    display: inline-flex;
    width: auto;
  }
}

.btn-nav {
  @extend %btn-large;
  @extend %btn-stroked;
  margin-left: 0;
  font: var(--button-font);

  &.btn-left-icon img {
    margin-right: 8px;
  }
}

.btn-nav-icon {
  @extend %btn-icon;
  margin-left: 0;
}

.btn-add-item {
  display: block;
  margin: 0 32px;
  border-color: var(--brand);
  box-shadow: 0 3px 6px var(--brand-shadow);
  background-color: var(--brand);
  width: 115px;

  &:hover {
    box-shadow: 0 3px 6px var(--brand-dark-shadow);
    background-color: var(--brand-dark);

    &:disabled {
      background-color: var(--brand);
    }
  }
}

.btn-w-notification {
  position: relative;
}

.btn-verification {
  @extend %btn-icon;
  cursor: default;

  &--small {
    border: 2px solid #fff;
    width: 16px;
    min-width: 16px;
    height: 16px;

    img {
      // transform: scale(0.5);
    }
  }
}

$btn-verifications: ('facebook' $facebook 3.77px 8px, 'email' $secondary 8px 6px, 'phone' $gold 6px 8px);

@each $name, $colour, $smallImgWidth, $smallImgHeight in $btn-verifications {
  .btn-verification--#{$name} {
    border-color: $colour;
    background-color: $colour;

    &:hover {
      border-color: $colour;
      background-color: $colour;
    }

    &.btn-verification--small {
      border: 2px solid #fff;

      img {
        width: $smallImgWidth;
        height: $smallImgHeight;
      }
    }
  }
}

/*====================
container to hold multiple buttons
they will fill the space
====================*/
.multi-btn-container {
  display: flex;
  flex: 1;

  button,
  a.btn {
    flex: 1;
    margin-right: 16px !important;
    margin-left: 0;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

/*====================
active request / loading state
====================*/
@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.btn-loading {
  position: relative;
  opacity: 0.5;
  color: transparent !important;
  pointer-events: none;

  &::after {
    display: block;
    position: absolute !important;
    top: calc(50% - (2rem / 2));
    left: calc(50% - (2rem / 2));
    border-radius: 50%;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    width: 2rem;
    height: 2rem;
    animation: spinAround 0.6s infinite linear;
    content: '';
  }
}

.btn-icon.btn-facebook,
.btn-icon.btn-messenger,
.btn-icon.btn-instagram,
.btn-icon.btn-twitter {
  align-items: center;
}
