/*====================
TAGS
default size: small
====================*/
%tag {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding-right: 6px;
  padding-left: 6px;
  width: fit-content;
  height: 16px;
  text-transform: uppercase;
  white-space: nowrap;
  color: #fff;
  font: var(--tag-font);
}

%tag-big {
  padding-right: 8px;
  padding-left: 8px;
  height: 20px;
  font: var(--tag-big-font);
}

.tag {
  @extend %tag;

  /*-- big --*/
  &--big {
    @extend %tag-big;
  }

  /*-- colour modifiers --*/
  &--admin {
    background-color: $primary;
  }

  &__dot {
    color: $dark-grey;
  }

  &--own {
    background-color: $darkest-grey;
  }

  &--featured {
    background-color: $green;
  }

  &--new {
    background-color: $secondary;
  }

  &--following {
    background-color: $green;
  }
}

.item-tag {
  @extend %tag;

  &--big {
    @extend %tag-big;
  }

  &--own-item {
    background-color: $darkest-grey;
  }

  &--featured-item {
    background-color: $green;
  }

  &--new-item {
    background-color: $secondary;
  }
}

.user-tag {
  @extend %tag;

  &--big {
    @extend %tag-big;
  }

  &--following {
    background-color: $green;
  }

  &--student-roost {
    background-color: $student-roost;
  }
}
