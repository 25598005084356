@import 'colours', 'breakpoints';

.search-grid-view {
  .loading-data {
    pointer-events: none;

    .grid-item__image img {
      display: block;
      background: $lightest-grey;
    }

    .grid-item__tag {
      display: none;
    }

    .grid-item__meta {
      margin: 8px 0 4px;
      background-color: var(--loading-colour);
      padding: 0;
      color: transparent !important;
    }

    .grid-item__meta__category {
      width: 100px;
    }

    .grid-item__meta__price {
      width: 50px;
    }

    .list-item__description {
      height: 6.4rem;
    }

    &.grid-item--user {
      .grid-item__content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .grid-item__content__top {
        display: block;
        margin-bottom: 8px;
        width: 100%;
      }

      .grid-item__user-location {
        width: 70%;
      }
    }
  }

  &.grid-view-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(126px, 1fr));
    margin-bottom: 72px;
    gap: 72px 16px;

    &--users {
      margin-bottom: 109px;
      gap: 109px 16px;
    }
  }

  &.list-view-container {
    display: grid;
    grid-template-rows: repeat(auto-fill, 160px);
    grid-template-columns: 1fr;
    row-gap: 24px;
  }

  .grid-item {
    display: block;
    position: relative;
    border-radius: 8px;
    padding-top: 100%;

    &:hover .grid-item__image {
      opacity: 0.75;
    }

    &__favourite,
    &__tag {
      position: absolute;
      right: 8px;
      z-index: 1;
    }

    &__favourite {
      bottom: 8px;
    }

    &__tag {
      top: 8px;
    }

    &__image,
    &__content {
      position: absolute;
      width: 100%;
    }

    &__image {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: all 300ms ease-in-out;
      border: 1px solid rgba($darkest-grey, 0.05);
      border-radius: 9px;

      img {
        border-radius: 8px;
        background: $lightest-grey;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__meta {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0 4px;

      &__category {
        color: #949191;
        font: var(--feed-item-description);
      }

      &__price {
        font: var(--feed-item-price);
      }
    }

    &__name {
      height: 24px;
      color: #444141;
      font: var(--feed-item-name);
    }
  }

  .grid-item--user {
    .grid-item__image,
    .grid-item__image img {
      border-radius: 50%;
    }

    .grid-item__content {
      text-align: center;

      &__top {
        display: flex;
        align-items: center;
        justify-content: center;

        .grid-item__name {
          height: 17px;
        }

        .verified-badge {
          margin-left: 8px;
        }
      }
    }

    .grid-item__user-location {
    }

    .grid-item__image,
    .grid-item__user-location {
      margin-bottom: 8px;
    }
  }

  .list-item {
    display: flex;

    &:hover .list-item__image img {
      opacity: 0.75;
    }

    &__image {
      position: relative;
      margin-right: 16px;
      width: 160px;
      height: 160px;

      &__thumbnail {
        display: block;
        transition: all 300ms ease-in-out;
        border-radius: 8px;
        background: $lightest-grey;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__content {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
    }

    .grid-item__meta {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 0;

      &__price {
        padding-top: 4px;
      }
    }

    .grid-item__meta__category,
    &__name,
    &__description {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
    }

    .grid-item__meta__category {
      -webkit-line-clamp: 1;
    }

    &__name {
      margin-bottom: 8px;
      color: #444141;
      font: var(--feed-item-name);
      -webkit-line-clamp: 2;
    }

    &__description {
      -webkit-line-clamp: 4;
    }
  }
}

@include x-small {
  .search-grid-view {
    &.grid-view-container {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      margin-bottom: 72px;
      gap: 72px 16px;

      &--users {
        margin-bottom: 109px;
        gap: 109px 16px;
      }
    }
  }
}

@include tablet {
  .search-grid-view {
    &.grid-view-container {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }

    &.list-view-container {
      grid-template-rows: repeat(auto-fill, 200px);
    }

    .list-item {
      &__image {
        width: 200px;
        height: 200px;
      }

      &__description {
        -webkit-line-clamp: 6;
      }
    }

    .loading-data .list-item__description {
      height: 9.6rem;
    }
  }
}

@include desktop {
  .search-grid-view {
    &.grid-view-container {
      grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
      margin-bottom: 76px;
      gap: 76px 16px;

      &--users {
        margin-bottom: 112px;
        gap: 112px 16px;
      }
    }

    &.list-view-container {
      grid-template-rows: repeat(auto-fill, 250px);
      padding-left: 16px;
    }

    .list-item {
      &__image {
        width: 250px;
        height: 250px;
      }

      &__description {
        -webkit-line-clamp: 5;
      }
    }

    .loading-data .list-item__description {
      height: 14.4rem;
    }

    .grid-item--user .grid-item__content__top .grid-item__name {
      height: 24px;
    }
  }
}
