/*====================
ICONS
====================*/

svg {
  width: inherit;
  height: inherit;
}

.dropdown-arrow {
  transition: transform 0.2s ease-in-out;
  width: 8px;
  height: 4px;

  &--rotate {
    transform: rotate(-180deg);
  }
}
