@import 'headers', 'tab-bar', 'card', 'grid-view/grid-view', 'menu', 'modals', 'checkbox-list', 'search',
  'settings-mobile-navigation', 'settings-mobile-page', 'tip', 'coachmarks', 'small-data', 'fullscreen-edit',
  'search-grid-view', 'grid-view-new/grid-view-new', './grey-info-bar', './web-to-app-banner';

pc-category-scroll {
  width: 100%;
}

.payment-address {
  margin-right: 16px;
  width: 100%;

  .highlight-text {
    margin-bottom: 8px;
  }
}

.payment-card {
  display: flex;
  flex: 1;
  align-items: flex-start;
  width: 100%;

  &__info {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 8px;

    &__detail {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-right: 0;

      &:first-of-type {
        // margin-bottom: -2px;

        .highlight-text {
          margin-bottom: 0;
        }

        p:last-of-type {
          font: var(--paragraph-large-font);
          font-family: var(--font-light);
        }
      }

      &:last-of-type {
        flex-direction: column;
        align-items: flex-start;
      }

      p {
        word-break: break-all;
      }

      .highlight-text {
        margin: 0 8px 0 0;
      }
    }
  }
}

.card-address {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}
