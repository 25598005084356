.card-container {
  &__title {
    display: flex;
    align-items: center;

    // justify-content: space-between;
    margin-bottom: 16px;

    h2 {
      margin-right: 24px;
    }

    h3 {
      margin-right: 8px;
    }

    &--fs {
      justify-content: flex-start;
    }
  }
}

// todo: change to .card once i've replaced the old ones
.content-card {
  @include e2e;
  margin-top: 0;
  margin-bottom: 8px;
  border-top: 1px solid $lightest-grey;
  border-bottom: 1px solid $lightest-grey;
  background-color: #fff;
  padding: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &--user {
    margin: 0;
    border: 0;
    padding: 0;
  }

  p {
    word-break: break-word;
  }
}

@include tablet {
  .card-container__title {
    padding-right: 54px;
    padding-left: 54px;
  }

  .content-card {
    padding-right: 80px;
    padding-left: 80px;
  }
}

@include desktop {
  .card-container__title {
    padding-right: 0;
    padding-left: 0;
  }

  .content-card {
    margin: 0 0 16px;
    border: 1px solid $lightest-grey;
    border-radius: 4px;
    padding: 16px;

    &--user {
      margin: 0;
      padding: 24px;
    }

    &--extra-padding {
      padding: 24px 108px;
    }
  }
}
