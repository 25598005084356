.ngx-image-swiper {
  .pagination__dot {
    box-shadow: 0 1px 2px rgba(#515151, 0.2) !important;
    background-color: $lightest-grey !important;

    &--active {
      background-color: $darkest-grey !important;
    }
  }
}
