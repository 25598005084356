input[type='checkbox']:disabled {
  pointer-events: none;
}

// container for custom checkboxes
.checkbox {
  position: relative;
  cursor: pointer;
  width: 32px;
  min-width: 32px;
  height: 32px;

  &::before {
    display: inline-block;
    position: absolute;
    transition: all 0.2s ease-in-out;
    border: 0;
    border-radius: 50%;
    background-color: $lightest-grey;
    width: 32px;
    height: 32px;
    content: '';
  }

  &:hover::before {
    background-color: $light-grey;
  }

  // add focus styles on the outer-box of the fake checkbox for accessibility
  &:focus {
    outline: rgb(59, 153, 252) auto 5px;
  }

  input,
  label {
    display: none;
  }

  &__checked {
    display: none;
    position: absolute;
    top: 0;
    width: 32px;
    height: 32px;
    pointer-events: none;

    circle {
      fill: transparent;
    }
  }

  // checked style for checkbox
  &--checked {
    &::before {
      background-color: $green;
    }

    &:hover::before {
      background-color: $green-dark;
    }

    .checkbox__checked {
      display: block;
    }
  }

  &--disabled {
    pointer-events: none;
  }

  &--small {
    width: 24px;
    height: 24px;

    &::before {
      width: 24px;
      height: 24px;
    }

    .checkbox__checked {
      width: 24px;
      height: 24px;
    }
  }
}
