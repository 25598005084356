/*====================
IMAGES
====================*/
.bg-image {
  border: 1px solid rgba($darkest-grey, 0.05);
  border-radius: 4px;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;

  img,
  > img {
    object-fit: cover;
  }
}
