/*====================
GRID VIEW
There are two variations of the grid view.
- We have the standard grid for displaying items
on pages like search.
- We also have custom styles for the grid for the home
feed page which is more dynamic and customisable.
====================*/

/*-- base grid styles --*/
.grid-view {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;

  &__item-container {
    padding: 8px;
    width: calc(100% / 2);
    height: 100%;

    &--3-col {
      width: calc(100% / 3);
    }

    &--user {
      padding: 8px 8px 16px;
    }
  }

  .item {
    display: block;
    cursor: pointer;

    &:hover {
      .item__image {
        opacity: 0.75;
      }
    }

    &__image {
      transition: 300ms ease-in-out;

      .tag {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
  }

  &--no-data {
    justify-content: center;
    padding: 10% 0;
  }
}

.grid-view-load-more {
  .loading-more-spinner {
    margin: 16px 0;
  }
}

/*====================
items - items etc.
====================*/
.item--item,
.item--deal {
  height: 176px;

  .item__image {
    position: relative;
    height: 120px;
  }

  .item__info {
    display: flex;
    flex-direction: column;
    height: 56px;

    .item-meta {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 8px 0 4px;

      &__left {
        position: relative;
        width: 100%;
        height: auto;
        overflow: hidden;
        white-space: nowrap;
        font: var(--feed-item-description);

        &.faded-content::after {
          width: 50px;
          height: 1.2rem;
        }
      }

      &__right {
        font: var(--feed-item-price);
      }
    }

    .item-name {
      position: relative;
      height: auto;
      height: 3.4rem;
      overflow: hidden;
      word-break: break-word;
      color: $darkest-grey;
      font: var(--feed-item-name);

      &.faded-content::after {
        width: 50px;
        height: 1.4rem;
      }
    }
  }
}

.item--item .item__image .save-item {
  position: absolute;
  right: 8px;
  bottom: 8px;
  margin: 0;
  width: 32px;
  height: 32px;
}

.item--user {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 163px;
  height: 200px;

  .item__image {
    border-radius: 50%;
    width: 120px;
    height: 120px;

    img {
      border-radius: 50%;
    }
  }

  .item__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80px;

    .user-name,
    .user-location {
      width: 100%;
      text-align: center;
    }

    .user-name {
      display: block;
      position: relative;
      margin: 8px 0 4px;
      height: 1.7rem;
      overflow: hidden;
      color: $darkest-grey;
      font: var(--feed-item-name);
    }

    .user-location {
      margin-bottom: 8px;
      height: 1.2rem;
      font: var(--feed-item-description);
    }

    .faded-content::after {
      width: 25px;
    }
  }
}

/*====================
loading states
====================*/
.item--loading {
  cursor: default !important;
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;

  .item__image {
    background: $lightest-grey;
  }

  p,
  a {
    background: $lightest-grey;
    color: transparent !important;
  }

  &.item--item,
  &.item--deal {
    .item-meta__left,
    .item-meta__right {
      height: 1.2rem;
    }

    .item-meta__left {
      width: 60%;
    }

    .item-meta__right {
      width: 30%;
    }

    .item-name {
      width: 85%;
      height: 2.4rem;
    }
  }

  &.item--user {
    .item__info {
      .user-name {
        width: 70%;
        height: 1.7rem;
      }

      .user-location {
        width: 60%;
      }
    }
  }
}

/*====================
custom grid layouts
====================*/
.grid-view--feed {
  // only display 4 items
  &.grid-view--rows-1 {
    .grid-view__item-container:nth-of-type(1n + 5) {
      display: none;
    }
  }

  // only display 6 items
  &.grid-view--rows-2 {
    .grid-view__item-container:nth-of-type(1n + 7) {
      display: none;
    }
  }
}

.swipe-grid {
  &__header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__buttons {
      display: flex;

      a.btn {
        margin: 0 -16px 0 0;
        border: 0;
      }

      pc-arrow-icon {
        display: none;
      }
    }
  }

  .grid-view {
    @include e2e;
    position: relative;
    flex-wrap: nowrap;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 16px;
    width: 100vw;
    overflow: auto hidden;

    .grid-view__item-container {
      padding: 0 6px;
      width: auto;

      &:first-of-type {
        padding-left: var(--grid-container-padding);
      }

      &:last-of-type {
        padding-right: var(--grid-container-padding);
      }
    }

    .item {
      width: 163px;
    }
  }
}

/*====================
responsiveness
====================*/
@include x-small {
  .grid-view {
    &__item-container {
      width: 100%;
    }
  }
}

@include tablet {
  .grid-view {
    &__item-container {
      width: calc(100% / 3);
    }

    .item--item,
    .item--deal {
      height: 216px;

      .item__image {
        height: 160px;
      }

      .item__info {
        height: 56px;
      }
    }

    .item--user {
      width: 228px;
      height: 240px;

      .item__image {
        width: 160px;
        height: 160px;
      }
    }

    .item--collection {
      height: 246px;

      .item__image {
        height: 177px;
      }
    }
  }

  // custom grid layouts
  .grid-view--feed {
    // only display 3 items
    &.grid-view--rows-1 {
      .grid-view__item-container:nth-of-type(1n + 4) {
        display: none;
      }
    }
  }

  .swipe-grid .grid-view {
    .grid-view__item-container {
      padding: 0 8px;
    }

    .item {
      width: 228px;
    }
  }
}

@include desktop {
  .grid-view {
    &__item-container {
      width: calc(100% / 4);
    }

    .item--item,
    .item--deal {
      height: 289px;

      .item__image {
        height: 210px;
      }

      .item__info {
        height: 79px;

        .item-meta {
          padding: 10px 0 6px;

          &__left.faded-content::after {
            height: 1.6rem;
          }
        }

        .item-name {
          height: 4.8rem;

          &.faded-content::after {
            height: 1.8rem;
          }
        }
      }
    }

    .item--user {
      width: 308px;
      height: 310px;

      .item__image {
        width: 210px;
        height: 210px;
      }

      .item__info {
        height: 100px;

        .user-name {
          margin: 10px 0 6px;
          height: 2.4rem;
        }

        .user-location {
          margin-bottom: 12px;
          height: 1.6rem;
        }
      }
    }

    .item--collection {
      height: 314px;

      .item__image {
        height: 210px;
      }
    }
  }

  // custom grid layouts
  .grid-view--feed {
    // only display 4 items
    &.grid-view--rows-1 {
      .grid-view__item-container {
        &:nth-of-type(1n + 4) {
          display: block;
        }

        &:nth-of-type(1n + 5) {
          display: none;
        }
      }
    }

    // only display 8 items
    &.grid-view--rows-2 {
      .grid-view__item-container {
        &:nth-of-type(1n + 7) {
          display: block;
        }

        &:nth-of-type(1n + 9) {
          display: none;
        }
      }
    }
  }

  .swipe-grid {
    &__header__buttons {
      a.btn {
        margin-right: 24px;
        border: 1px solid $grey;
      }

      pc-arrow-icon {
        display: none;
        display: flex;
        width: 32px;
        height: 32px;

        &:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .grid-view {
      margin: 0;
      width: 100%;

      .grid-view__item-container {
        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }

      .item {
        width: 308px;
      }
    }
  }
}

@import './grid-view-item';
