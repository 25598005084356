/*====================
overrides for angular
material components
====================*/

.mat-snack-bar-container {
  margin: 16px !important;
  box-shadow: 0 2px 4px rgba(darken($darkest-grey, 10), 0.3);
  background-color: $darkest-grey;
  padding: 16px 24px !important;
  border-radius: 8px !important;

  .mat-simple-snackbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 1;
    line-height: 20px;

    span {
      color: #fff;
      font: var(--paragraph-font);
      font-size: 1.4rem !important;
    }

    .mat-simple-snackbar-action {
      margin: 0 0 0 16px;

      button {
        margin: 0;
        background: none;
        padding: 0;
        max-height: none;
        font: var(--paragraph-font);
        font-family: var(--font-semi-bold);
        font-size: 1.6rem !important;
        background-color: #fff;

        span {
          color: $darkest-grey;
        }
      }
    }
  }
}

.mat-tooltip {
  box-shadow: 0 3px 12px rgba($darkest-grey, 0.2);
  background: #fff;
  padding: 16px !important;
  max-width: 280px !important;
  color: $darkest-grey !important;
  font: 1.6rem / 2.4rem var(--font-regular);
}
