/*====================
colour palette
====================*/

// brand css variables that can be modified by javascript
:root {
  --brand: #f71d3b;
  --brand-shadow: #f71d3b32;
  --brand-dark: #c6172f;
  --brand-dark-shadow: #c6172f32;
}

// feature
$primary: #f71d3b;
$primary-dark: #c6172f;
$secondary: #1a76f0;
$secondary-dark: #155ec0;

// action
$green: #25d61e;
$green-dark: #1eab18;
$red: #c81633;
$red-dark: #a01229;

// greys
$darkest-grey: #444141;
$dark-grey: #949191;
$grey: #cdcaca;
$light-grey: #e3e0e0;
$lightest-grey: #f2f1f1;
$background-grey: #fbfafa;

// miscellaneous & social
$gold: #ffaf0c;
$facebook: #3b5998;
$twitter: #55acee;
$messenger: #0084ff;
$instagram: #507ea1;
$apple: #000000;
$student-roost: #2d3746;
