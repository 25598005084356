.table-data {
  width: 100%;

  tr {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  td {
    display: flex;
    align-items: center;
    flex: 1;

    &:first-of-type img {
      margin-right: 8px;
    }

    &:last-of-type {
      justify-content: flex-end;
      text-align: right;
    }

    p {
      word-break: initial;
    }
  }

  &--align-centre tr {
    align-items: center;
  }

  &--extra-margin tr {
    margin-bottom: 16px;
  }
}
