/*====================
material slider overwrites
====================*/
.mat-slider {
  margin: 6px 0 24px;
  padding: 0 !important;
  width: calc(100% - 20px);
  min-width: auto !important;
  height: 32px !important;
  align-self: center;
}

/*-- slider line --*/
.mat-slider-horizontal .mat-slider-track-wrapper,
.mat-slider-horizontal .mat-slider-track-background,
.mat-slider-horizontal .mat-slider-track-fill,
.mat-slider-track-fill,
.mat-slider-ticks-container {
  height: 4px !important;
}

.mat-slider-horizontal .mat-slider-wrapper {
  right: 0 !important;
  left: 0 !important;
  height: 4px !important;
}

.mat-slider-horizontal .mat-slider-track-wrapper,
.mat-slider-track-fill {
  border-radius: 4px;
}

.mat-slider-track-background,
.cdk-focused .mat-slider-track-background,
.mat-slider:hover .mat-slider-track-background {
  background-color: $lightest-grey !important;
}

.mat-slider-track-fill {
  background-color: $primary !important;
}

/*-- slide grabber --*/
.mat-slider-thumb {
  top: -15px;
  transform: none !important;
  border: 1px solid $light-grey !important;
  box-shadow: 0 3px 3px rgba($darkest-grey, 0.2);
  background-color: #fff !important;
  width: 32px !important;
  height: 32px !important;
}

.mat-slider-horizontal .mat-slider-thumb-container {
  margin-left: 10px;
}

.mat-slider-focus-ring {
  display: none;
}

/*-- disabled states --*/
.mat-slider-disabled {
  cursor: not-allowed;
}

.mat-slider-disabled .mat-slider-thumb,
.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill {
  opacity: 0.8;
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill {
  background-color: $lightest-grey !important;
}

.mat-slider-disabled .mat-slider-thumb {
  transform: none !important;
}
