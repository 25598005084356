/* ====================
DEFAULT LAYOUT
- styles for the base layout of paperclip:
header
content
footer
==================== */
:root {
  --header-height: 68px;
}

@include tablet {
  :root {
    --header-height: 80px;
  }
}

#paperclip {
  display: grid;
  grid-template-rows: var(--header-height) 1fr;
  grid-template-columns: 1fr;
  transition: filter 300ms ease;

  // transition is only supported in firefox (as of Aug 2019)
  // no good using it until wider support as it looks ugly
  // leaving here as a reminder
  // transition: grid-template-rows 300ms ease;
  height: 100vh;
  min-height: 100vh;

  &.paperclip-messages {
    grid-template-rows: var(--header-height);
    height: auto !important;
    min-height: auto !important;

    main,
    footer {
      display: none;
    }

    &--no-header {
      display: none;
    }

    &.mobile-search-open {
      grid-template-rows: 124px !important;
    }

    &.email-banner-visible {
      grid-template-rows: 50px var(--header-height) !important;

      &.mobile-search-open {
        grid-template-rows: 50px 124px !important;
      }

      ~ pc-messages .messages-container--desktop {
        height: calc(100% - calc(var(--header-height) + 50px));
      }

      // child conversations list component
      ~ pc-messages main .conversations-list {
        height: calc(100vh - calc(var(--header-height) + 50px) - calc(var(--messaging-header-height) + 8px));
      }
    }
  }

  &.mobile-search-open {
    grid-template-rows: 124px 1fr;

    header,
    .header-container {
      height: 124px;
    }

    .grey-info-bar--search {
      top: calc(var(--header-height) + 56px);
    }

    .search--searching-user .filters-bar-container,
    .search--searching-user .list-view-filters {
      top: calc(var(--header-height) + 88px);
    }
  }

  &.email-banner-visible {
    grid-template-rows: 50px var(--header-height) 1fr;

    &.mobile-search-open {
      grid-template-rows: 50px 124px 1fr;

      .grey-info-bar--search {
        top: calc(var(--header-height) + 106px);
      }

      .search--searching-user .filters-bar-container,
      .search--searching-user .list-view-filters {
        top: calc(var(--header-height) + 138px);
      }
    }

    // cover email banner when mobile search is focused
    .mobile-search--focused {
      top: -50px;
    }

    .header-container {
      top: 50px;
    }

    // if on search we need to adjust the position of the filter bar & side filters
    .search {
      .filters-bar-container,
      .list-view-filters {
        top: calc(var(--header-height) + 50px);
      }

      &--searching-user .filters-bar-container,
      &--searching-user .list-view-filters {
        top: calc(var(--header-height) + 82px);
      }
    }

    .grey-info-bar--search {
      top: calc(var(--header-height) + 50px);
    }

    &.tablet-search-open .search-results-container {
      top: 129px;
    }
  }

  main {
    padding-bottom: 24px;
  }

  &.no-footer pc-site-footer {
    display: none;
  }
}

@include tablet {
  #paperclip {
    grid-template-rows: var(--header-height) 1fr;
  }
}

@include desktop {
  #paperclip {
    grid-template-rows: var(--header-height) 1fr;

    &.paperclip-messages.email-banner-visible ~ pc-messages main .messaging {
      height: calc(100% - calc(var(--header-height) + 50px)) !important;
    }
  }
}
