/*====================
ANIMATIONS
====================*/
@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulseLowerOpacity {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 0.5;
  }
}

.loading-data {
  --loading-colour: #f2f1f1;
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;

  &--dark {
    --loading-colour: #e3e0e0;
  }

  pc-notification-dot {
    display: none;
  }

  img,
  h1,
  h2,
  h3,
  p,
  a,
  .table-data,
  li {
    background-color: var(--loading-colour);
    color: transparent !important;
  }

  button {
    color: transparent !important;
    pointer-events: none;
  }

  h1 {
    height: 2.8rem;
  }

  h2,
  h3 {
    height: 2.4rem;
  }

  p,
  a:not(.btn) {
    height: 1.6rem;
  }

  a.btn {
    border-color: var(--loading-colour) !important;
  }

  .p-large {
    height: 1.8rem;
  }

  .p-small {
    height: 1.4rem;
  }

  .sub-text {
    height: 1.2rem;
  }

  .table-data {
    width: 100%;
    height: 50px;
  }

  .faded-content::after {
    content: none;
  }

  img {
    display: none;
  }

  .user-profile-picture img {
    display: block !important;
  }

  .card-container__title h3 {
    background-color: transparent;
    color: initial !important;
  }

  .checkbox-list {
    li {
      background-color: transparent;
    }

    div:first-of-type .highlight-text {
      width: 200px;
      max-width: 80%;
    }

    pc-checkmark-icon {
      display: none;
    }
  }

  a {
    pointer-events: none;
  }

  .nav-list__title {
    background-color: transparent;
  }

  .preview__image {
    background-color: var(--loading-colour) !important;
  }

  input,
  select {
    pointer-events: none;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-more-spinner {
  display: flex;
  align-self: center;
  margin: 0 auto;
  border: 3px solid rgba($red, 0.1);
  border-radius: 50%;
  border-top-color: $red;
  width: 50px;
  height: 50px;
  animation: spin 1s ease infinite;

  &--small {
    margin: 16px auto !important;
    width: 24px;
    height: 24px;
  }
}
