/*====================
overwrite styles for
ngx-image-cropper
====================*/
image-cropper {
  max-height: calc(100% - 48px) !important;
}

.ngx-ic-resize {
  z-index: 2 !important;
  opacity: 1 !important;
}

.ngx-ic-topleft,
.ngx-ic-topright,
.ngx-ic-bottomright,
.ngx-ic-bottomleft {
  .ngx-ic-square {
    background: rgba($color: #fff, $alpha: 0.6) !important;
    border: 1px solid $primary !important;
  }
}

.ngx-ic-top,
.ngx-ic-right,
.ngx-ic-bottom,
.ngx-ic-left {
  display: none !important;
}

.ngx-ic-cropper {
  outline-color: rgba(255, 255, 255, 0.6) !important;
  &::after {
    border: 1px solid $darkest-grey !important;
  }
}
