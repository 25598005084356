/*====================
HEADERS - PROFILE, TRADING ETC
====================*/

/*---- device specific display rules ----*/
.small-page-header {
  display: block;
  border-bottom: 1px solid $light-grey;
  background-color: #fff;
  height: 48px;

  .btn-link img {
    width: 10px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 12px 1fr minmax(12px, auto);

    h3 {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &--no-bb {
    border-bottom: none;
  }

  &--2-rows {
    display: flex;
    flex-direction: column;
    min-height: 92px;
    height: auto;

    .grid-container {
      height: 48px;

      &.small-page-header__subtitle {
        display: flex;
        align-items: center;
        min-height: 44px;
        height: auto;
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }
  }
}

.basic-header {
  display: none;
}

@include desktop {
  .basic-header {
    display: block;
  }
}

/*---- generic styling shared between both headers ----*/

.basic-header {
  border-bottom: 1px solid $lightest-grey;
  background-color: #fff;
  height: fit-content;

  &__left,
  &__right {
    align-items: center;
    display: flex;
  }
}

.top-bar .grid-container,
.main-content .grid-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-content .grid-container {
  h1,
  h3 {
    flex: 1;
  }

  div {
    display: flex;
  }
}

/*---- basic-header specific heights and styling rules ----*/
.basic-header {
  .top-bar .grid-container {
    height: 57px;

    img {
      padding-right: 16px;
    }
  }

  .main-content .grid-container {
    padding-bottom: 24px;
    height: 88px;
  }
}

/*---- tablet specific styling ----*/
@include tablet {
  .basic-header .top-bar .grid-container,
  .basic-header .main-content .grid-container {
    padding-right: 80px;
    padding-left: 80px;
  }
}
