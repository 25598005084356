/*====================
FORM ROWS & GROUPS
====================*/
.form-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -8px 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &--group {
    margin-bottom: 0;

    .form-group {
      margin-bottom: 24px;
    }
  }
}

.form-group {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  padding: 0 8px;

  &--force-inline {
    flex-basis: 0;
  }

  label {
    margin-bottom: 4px;
    color: $darkest-grey;
    font: var(--h3-font);
  }

  &__labels {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    label {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }

  &--checkbox {
    flex-direction: row;
    cursor: pointer;

    label {
      margin-left: 16px;
      cursor: pointer;
      color: $dark-grey;
      font: var(--paragraph-font);
    }
  }
}

@include desktop {
  .form-group {
    flex-basis: 0;
  }
}
