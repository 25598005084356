.checkbox-list {
  &:last-of-type {
    padding-bottom: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding-bottom: 16px;

    &:last-of-type {
      padding-bottom: 0 !important;
    }

    > div:first-of-type {
      margin-right: 16px;
    }

    &__actions {
      display: flex;
    }

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &--detailed {
    .checkbox-list__item {
      align-items: flex-start;
      padding: 8px 0;

      &:first-of-type {
        padding-top: 0;
      }

      .highlight-text {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        img {
          margin-left: 8px;
        }
      }
    }
  }

  &.loading-data {
    .checkbox-list__item {
      background-color: transparent;

      p:last-of-type {
        width: 60%;
      }

      a {
        display: none;
      }
    }

    pc-checkmark-icon {
      display: none;
    }
  }
}
