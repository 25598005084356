.fullscreen-edit {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: #fff;
  padding: 0 var(--grid-container-padding);

  &__header {
    @include e2e;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    border-bottom: 1px solid $light-grey;
    padding: 0 16px;
    height: 48px;

    h3 {
      flex: 1;
      text-align: center;
    }

    img {
      transform: rotate(180deg);
      width: 10px;
      height: 19px;
    }
  }

  &__content {
    height: calc(100% - 64px - 89px);
    overflow-x: hidden;
    overflow-y: scroll;
  }

  &__footer {
    display: flex;
    position: fixed;
    right: 16px;
    bottom: 0;
    left: 16px;
    justify-content: flex-end;
    margin: 0 -16px;
    border-top: 1px solid $lightest-grey;
    padding: 16px;

    button {
      margin-right: 0;
    }
  }
}
