/*=========================
TAB-BAR
=========================*/

.tab-bar {
  border-bottom: 1px solid $lightest-grey;
  background-color: #fff;
  height: 64px;

  &--tb {
    border-top: 1px solid $lightest-grey;
  }

  .grid-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }

  &__left,
  &__right {
    display: flex;

    button,
    a.btn {
      &:last-of-type {
        position: relative;

        // this is a little hack to force right padding in the scroll div
        // so there's space between the last button and the right side of the screen
        &::after {
          display: block;
          position: absolute;
          right: calc(var(--grid-container-padding) * -1);
          width: var(--grid-container-padding);
          height: 1px;
          content: '';
        }
      }
    }
  }

  &__right {
    margin-left: 16px;
  }
}
