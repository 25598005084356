/*====================
innerHTML specific styles
====================*/

/*-- trading offer state text --*/
.offer-state-dot-separator {
  color: $dark-grey;
}

.offer-state-part-2 {
  color: $darkest-grey;
}

.offer-card__state--payment-error,
.offer-detail__state--payment-error {
  .offer-state-part-2 {
    color: $red;
  }
}

.notification__message a {
  font-family: var(--font-semi-bold) !important;
}

// stupid fix for edge
pc-checkmark-icon,
pc-close-icon,
pc-add-icon,
pc-social-icon,
pc-review-star-icon,
pc-message-icon-btn,
pc-delete-icon,
pc-close-icon,
pc-clear-input-btn,
pc-checkmark-icon,
pc-arrow-icon {
  width: auto;
  height: auto;
}
