/*====================
modals
uses @angular/cdk
====================*/

$cdk-z-index-overlay-container: 1000;
$cdk-z-index-overlay: 1000;
$cdk-z-index-overlay-backdrop: 1000;

// Background color for all of the backdrops
$cdk-overlay-dark-backdrop-background: rgba(0, 0, 0, 0.288);

// Default backdrop animation is based on the Material Design swift-ease-out.
$backdrop-animation-duration: 0.3s !default;
$backdrop-animation-timing-function: ease-in-out !default;

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  // The container should be the size of the viewport.
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  // Disable events from being captured on the overlay container.
  pointer-events: none;
}

// The overlay-container is an invisible element which contains all individual overlays.
.cdk-overlay-container {
  position: fixed;
  z-index: $cdk-z-index-overlay-container;

  &:empty {
    // Hide the element when it doesn't have any child nodes. This doesn't
    // include overlays that have been detached, rather than disposed.
    display: none;
  }
}

// We use an extra wrapper element in order to use make the overlay itself a flex item.
// This makes centering the overlay easy without running into the subpixel rendering
// problems tied to using `transform` and without interfering with the other position
// strategies.
.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: $cdk-z-index-overlay;
}

// A single overlay pane.
.cdk-overlay-pane {
  box-sizing: border-box;

  // For connected-position overlays, we set `display: flex` in
  // order to force `max-width` and `max-height` to take effect.
  display: flex;

  // Note: it's important for this one to start off `absolute`,
  // in order for us to be able to measure it correctly.
  position: absolute;
  z-index: $cdk-z-index-overlay;
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity $backdrop-animation-duration $backdrop-animation-timing-function;
  opacity: 0.7;
  z-index: $cdk-z-index-overlay-backdrop;
  background-color: #fff !important;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;

  &.cdk-overlay-backdrop-showing {
    opacity: 0.7;
  }
}

.cdk-overlay-dark-backdrop {
  background: $cdk-overlay-dark-backdrop-background;
}

.cdk-overlay-transparent-backdrop {
  // Note: as of Firefox 57, having the backdrop be `background: none` will prevent it from
  // capturing the user's mouse scroll events. Since we also can't use something like
  // `rgba(0, 0, 0, 0)`, we work around the inconsistency by not setting the background at
  // all and using `opacity` to make the element transparent.
  &,
  &.cdk-overlay-backdrop-showing {
    opacity: 0;
  }
}

// Overlay parent element used with the connected position strategy. Used to constrain the
// overlay element's size to fit within the viewport.
.cdk-overlay-connected-position-bounding-box {
  // We use `display: flex` on this element exclusively for centering connected overlays.
  // When *not* centering, a top/left/bottom/right will be set which overrides the normal
  // flex layout.
  display: flex;
  position: absolute;

  // We use the `column` direction here to avoid some flexbox issues in Edge
  // when using the "grow after open" options.
  flex-direction: column;
  z-index: $cdk-z-index-overlay;

  // Add some dimensions so the element has an `innerText` which some people depend on in tests.
  min-width: 1px;
  min-height: 1px;
}

// Used when disabling global scrolling.
.cdk-global-scrollblock {
  position: fixed;

  // Necessary for the content not to lose its width. Note that we're using 100%, instead of
  // 100vw, because 100vw includes the width plus the scrollbar, whereas 100% is the width
  // that the element had before we made it `fixed`.
  width: 100%;

  // Note: this will always add a scrollbar to whatever element it is on, which can
  // potentially result in double scrollbars. It shouldn't be an issue, because we won't
  // block scrolling on a page that doesn't have a scrollbar in the first place.
  overflow-y: scroll;
}

// when a modal opens on top of another, darken the backdrop
.cdk-global-overlay-wrapper + .cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.modal-backdrop {
  align-items: center;
  justify-content: center;
}

/*-- modal positions --*/
.modal {
  .modal-container {
    transition: all 0.3s ease-in-out;
    opacity: 0;
    background: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &__top {
      display: flex;
      position: absolute;
      top: 16px;
      right: 18px;
      align-items: center;
    }

    .close-modal {
      display: flex;
      position: absolute;
      top: 24px;
      right: 24px;
      align-items: center;
      justify-content: center;
      border-radius: 23px;
      cursor: pointer;
      padding: 10px;

      &:hover {
        background-color: $lightest-grey;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &--open {
      opacity: 1;
    }
  }

  &--left .modal-container,
  &--right .modal-container {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: inherit;
    padding: 16px 24px;
    width: 100%;

    // max-width: 375px;
    overflow-x: hidden;
    overflow-y: auto;

    &--open {
      transform: translateX(0) !important;
    }

    &__header {
      margin-top: 48px;

      h4 {
        margin: 0;
      }
    }
  }

  &--left .modal-container {
    left: 0;
    transform: translateX(-110%);
    box-shadow: 3px 0 16px rgba($darkest-grey, 0.2);
  }

  &--right .modal-container {
    right: 0;
    transform: translateX(110%);
    box-shadow: -3px 0 16px rgba($darkest-grey, 0.2);
  }

  &--centre {
    display: block;
    width: 100%;
    height: 100%;

    .modal-container {
      transform: translateY(3px);
      padding: 24px 16px;
      width: 100%;
      height: 100%;

      &--open {
        transform: translateY(0) !important;
      }

      &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        h1 {
          margin: 0;
        }
      }

      &__body {
      }

      &__footer {
        margin-top: 40px;
      }
    }
  }

  &--bottom {
    .modal-container {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateY(100%);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      box-shadow: 0 -3px 12px rgba($darkest-grey, 0.2);
      padding: 24px 26px 40px;

      &--open {
        transform: translateY(0) !important;
      }
    }
  }

  &--image-viewer {
    display: block;
    width: 100vw;
    height: 100vh;

    .modal-container {
      transform: translateY(3px);
      background: transparent;
      padding: 24px 16px;
      width: 100vw;
      height: 100vh;

      .close-modal {
        position: absolute;
        top: 24px;
        right: 24px;
      }

      &--open {
        transform: translateY(0) !important;
      }
    }
  }

  &--full-screen,
  &--image-viewer-new {
    display: block;
    width: 100%;
    height: 100%;

    .modal-container {
      transform: translateY(3px);
      padding: 24px 16px;
      width: 100%;
      height: 100%;

      .close-modal {
        position: absolute;
        top: 24px;
        right: 24px;
      }

      &--open {
        transform: translateY(0) !important;
      }
    }
  }

  &--full-screen {
    .modal-container {
      &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        h1 {
          margin: 0;
        }
      }

      &__body {
      }

      &__footer {
        margin-top: 40px;
      }
    }
  }

  &--image-viewer-new .modal-container {
    background: transparent;
  }
}

/*====================
tablet
====================*/
@include tablet {
  .modal .modal-container {
    &__top {
      top: 12px;
      right: 28px;
    }
  }

  .modal--centre {
    .modal-container {
      border-radius: 10px;
      box-shadow: 0 3px 12px rgba($darkest-grey, 0.2);
      padding: 40px;

      .close-modal {
        position: absolute;
        top: 24px;
        right: 24px;
      }
    }
  }
}

/*====================
desktop
====================*/
@include desktop {
  .modal--centre {
    width: auto;
    min-width: 525px;
    max-width: 750px;
    height: auto;
    max-height: 840px;

    // overflow-y: scroll;
    // box-shadow: 3px 0 16px rgba($darkest-grey, 0.2);

    &.modal--auto-height {
      max-height: 100%;

      .modal-container {
        max-height: 100%;
      }
    }

    .modal-container {
      width: auto;
      min-width: 525px;
      max-width: 750px;
      height: auto;
      max-height: 840px;
    }
  }

  .modal--left .modal-container,
  .modal--right .modal-container {
    max-width: 500px;
  }
}

// go full screen on small height desktop to enable scrolling
@media (max-height: 700px) {
  .modal--centre {
    width: 100%;
    max-width: none;
    height: 100%;

    .modal-container {
      width: 100%;
      max-width: none;
      height: 100%;
    }
  }
}
