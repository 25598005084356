/*====================
UTILITIES
classes for specific use-cases that can be applied
to more than one element/scenario
====================*/

.highlight-text {
  color: $darkest-grey;
  font-family: var(--font-regular);
}

.red-text {
  color: $primary;
}

.text-transform-none {
  text-transform: none !important;
}

// !! needs to be inside of a relative positions container
.scrollable-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-right: -16px; // space for scrollbar
  padding-right: 16px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

.smooth-scroll {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

.no-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.faded-content {
  position: relative;
  width: 100%;
  height: inherit;
  overflow: hidden;
  word-break: break-all;
  word-break: break-word;

  &::before,
  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    transition: opacity 300ms ease-in-out;
    width: 50px;
    height: inherit;
    content: '';
  }

  /*====================
  we can't yet use transition on gradients in css so the hack is to have this 2nd gradient
  that is hidden and transitions using opacity. on hover we set the opacity to 1
  and this makes it appear like it is transitioning
  ====================*/

  // content has white background
  &::before {
    opacity: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, $lightest-grey 50%, $lightest-grey 100%);
  }

  &::after {
    opacity: 1;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 50%, #fff 100%);
  }

  // content has grey background
  &--inversed {
    &::before {
      opacity: 0;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 50%, #fff 100%);
    }

    &::after {
      opacity: 1;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, $lightest-grey 50%, $lightest-grey 100%);
    }
  }

  &--message {
    &::before {
      opacity: 0;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 50%, #fff 100%);
    }

    &::after {
      opacity: 1;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, $lightest-grey 50%, $lightest-grey 100%);
    }

    &--open {
      &::before {
        opacity: 1;
      }

      &::after {
        opacity: 0;
      }
    }
  }
}

.no-scrollbar {
  -ms-overflow-style: none; // ie & edge
  scrollbar-width: none; // firefox

  &::-webkit-scrollbar {
    display: none; // safari & chrome
  }
}

.sold-item-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 !important;
  pointer-events: none;
  z-index: 1;

  &--background {
    opacity: 0.7;
    background-color: #fff;
  }

  &--foreground {
    display: flex;
    align-items: center;
    justify-content: center;

    .sold-tag {
      border-radius: 20px;
      background-color: $secondary;
      width: 92px;
      height: 40px;
      text-align: center;
      line-height: 40px !important;
      color: #fff;
    }
  }
}

.hide-scrollbar {
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox

  // Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }
}

// todo: i should figure out another way to do this
// when child components are involved otherwise this
// class could become too big for comfort
// .conversation:hover,
.results-section__list li:hover {
  .faded-content {
    &::before {
      opacity: 1;
    }

    &::after {
      opacity: 0;
    }
  }
}

// .conversation:not(.conversation--open):hover {
//   .faded-content--message--open {
//     &::before {
//       opacity: 0;
//     }

//     &::after {
//       opacity: 1;
//     }
//   }
// }
