.grey-info-bar {
  background-color: $lightest-grey;
  height: 32px;

  .grid-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    color: $darkest-grey;
    font: var(--feed-item-description);
  }

  button,
  pc-clear-input-btn {
    margin-left: 8px;
  }
}
