.auth-layout {
  position: relative;
  width: 100vw;
  height: 100vh;
  height: 100%;

  &__exit {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__left {
    background: #fff;
    padding: 56px 16px 16px;
    height: calc(100% - 56px);

    &__header,
    .leave-auth-btn {
      display: none;
    }

    &__content {
      height: 100%;

      main {
        height: inherit;

        pc-auth-signup,
        pc-auth-login,
        pc-auth-onboarding {
          width: 100%;
        }
      }
    }
  }

  &__right {
    display: none;
  }
}

.auth-form {
  &__title {
    margin-bottom: 24px;
    font: var(--modal-left-heading);
  }

  &__controls {
    &__password {
      margin-bottom: 0 !important;

      .input-group__label {
        cursor: pointer;
      }
    }

    &__referral-code {
      margin-top: 16px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    margin: 24px auto 16px;
    padding: 0 8px;

    .btn-large {
      margin: 0;
    }

    .forgot-password {
      justify-content: center;
      margin: 16px 0 24px;
    }

    &__marketing-consent {
      display: flex;
      align-self: center;
      justify-content: space-between;
      margin: 24px 0;

      p {
        padding-right: 16px;
      }
    }

    .word-separator {
      margin: 0 -8px;
    }

    .btn-facebook {
      margin: 24px 0 16px;
    }

    &__switch-form {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
    }
  }
}

@include tablet {
  .auth-layout {
    &__left {
      padding-top: 120px;
      padding-right: 148px;
      padding-left: 148px;

      main {
        margin: 0 auto;
        max-width: 600px;
      }
    }
  }

  .auth-form__footer {
    padding: 0;

    .word-separator {
      margin: 0;
    }
  }
}

@include desktop {
  .auth-layout {
    display: grid;
    grid-template-columns: 57% 43%;

    &__exit {
      display: none;
    }

    &__left {
      background: #fff !important;
      padding: 25px 40px;

      &__header,
      .leave-auth-btn {
        display: flex;
      }

      &__header {
        margin-bottom: 65px;

        img {
          width: 156px;
          height: 30px;
        }
      }

      &__content {
        display: flex;
        height: auto;

        .leave-auth-btn {
          margin-right: 56px;
        }

        main {
          display: flex;
          margin: 0 200px 0 0;
          width: 100%;
          min-width: 416px;
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: 50% 50% / cover no-repeat $background-grey;
      padding: 10vh 110px 160px;
      height: 100vh;
      text-align: center;

      h1 {
        margin-bottom: 24px;
      }

      p {
        color: $darkest-grey;
      }
    }

    &--onboarding .auth-layout__left__content {
      justify-content: center;

      main {
        margin: 0;
      }
    }
  }
}
