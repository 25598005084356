.mat-dialog-container,
.mat-bottom-sheet-container {
  pc-close-icon {
    display: flex;
    position: absolute;
  }
}

.mat-dialog-container {
  position: relative;

  pc-close-icon {
    top: 6px;
    right: 6px;
  }
}

.mat-bottom-sheet-container {
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 0 -3px 12px rgba($darkest-grey, 0.2);
  padding: 40px 16px !important;

  pc-close-icon {
    display: flex;
    position: absolute;
    top: 12px;
    right: 8px;
  }

  .checkbox-list__item > div:first-of-type {
    margin-right: 0;
  }

  .bottomsheet-options {
    display: flex;
    flex-direction: column;
    margin-top: 48px;

    [class*='btn'] {
      margin: 0 0 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.mat-bottom-sheet-container-medium {
  min-width: 100vw !important;
  max-width: none !important;
}

.mat-dialog-content {
  margin: 0 !important;
  padding: 0 !important;
}

/*====================
modal types
====================*/
.modal-container {
  .modal-body {
    position: relative;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 324px;

    button {
      margin: 0;
    }
  }

  &--left,
  &--centered,
  &--tabbed {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: none !important;

    .mat-dialog-container {
      border-radius: 0;
    }
  }

  &--generic,
  &--left,
  &--centered,
  &--tabbed {
    .mat-dialog-container {
      box-shadow: 0 3px 12px rgba($darkest-grey, 0.2);
    }
  }

  &--generic {
    max-width: 90vw !important;

    .mat-dialog-container {
      border-radius: 10px !important;
      padding: 40px 24px 24px !important;
      max-width: 327px;
    }

    .modal {
      text-align: center;
    }

    .modal-body {
      margin: 16px 0 40px;
    }
  }

  &--left {
    .mat-dialog-container {
      padding: 40px 16px !important;
    }

    .modal-header {
      margin-bottom: 24px;

      h1 {
        margin-bottom: 16px;
        font: var(--modal-left-heading);
      }
    }

    .modal-footer {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      justify-content: center;
      margin-top: 16px;
      border-top: 1px solid $lightest-grey;
      background-color: #fff;
      padding: 16px;
      padding-right: var(--grid-container-padding);
      padding-left: var(--grid-container-padding);
      max-width: none;
      text-align: right;
    }
  }

  &--centered {
    .mat-dialog-container {
      padding: 40px 16px 0 !important;
    }

    .modal {
      display: grid;
      grid-template-rows: auto 1fr auto;
      height: 100%;
    }

    .modal-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 -16px;
      border-bottom: 1px solid $lightest-grey;
      padding: 0 16px 16px;
      text-align: center;

      h1 {
        margin-bottom: 16px;
      }
    }

    .modal-body {
      margin: 24px 0;
    }

    .modal-footer {
      margin: 0 -16px;
      border-top: 1px solid $lightest-grey;
      padding: 16px 24px;
      width: auto;
      max-width: none;
    }
  }

  &--edit-image {
    width: 100vw;
    max-width: none !important;
    height: 100vh;

    .mat-dialog-container {
      border-radius: 0;
      box-shadow: none;
      background-color: transparent;
      padding: 0;
      width: 100vw;
      max-width: none !important;
      height: 100vh;

      pc-close-icon {
        top: 16px;
        right: 16px;
      }
    }

    .modal {
      // display: flex;
      // align-items: center;
      background: 0 0;
      padding: 24px 16px;
      width: 100vw;
      max-width: none !important;
      height: 100vh;
      background-color: #fff;

      .modal-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 -16px;
        border-bottom: 1px solid $lightest-grey;
        padding: 0 16px 16px;
        text-align: center;

        h1 {
          margin-bottom: 16px;
        }
      }

      .modal-body {
        margin: 24px 0;
        height: calc(100% - 179px);

        @media (max-width: 396px) {
          height: calc(100% - 200px);
        }

        @include desktop {
          height: calc(100% - 188px);
        }
      }

      .modal-footer {
        margin: 0 -16px;
        border-top: 1px solid $lightest-grey;
        padding: 16px 24px;
        width: auto;
        max-width: none;
      }
    }
  }

  &--image-viewer {
    width: 100vw;
    max-width: none !important;
    height: 100vh;

    .mat-dialog-container {
      border-radius: 0;
      box-shadow: none;
      background-color: transparent;
      padding: 0;
      width: 100vw;
      max-width: none !important;
      height: 100vh;

      pc-close-icon {
        top: 16px;
        right: 16px;
      }
    }

    .modal {
      display: flex;
      align-items: center;
      background: 0 0;
      padding: 24px 16px;
      width: 100vw;
      max-width: none !important;
      height: 100vh;

      .modal-body {
        position: relative;
        width: 100%;
        height: calc(100% - 200px);
      }
    }
  }

  &--tabbed {
    .mat-dialog-container {
      padding: 0;
      overflow: hidden;
    }

    .modal {
      display: grid;
      grid-template-rows: auto auto 1fr;
      height: 100%;

      .modal-header,
      .modal-tabs {
        background-color: #fff;
      }

      .modal-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 72px;
        padding: 0 16px;

        h1 {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 300px;
          text-align: center;
        }
      }

      .modal-tabs {
        height: 32px;
        display: flex;
        font-family: var(--font-regular);
        margin: 0;
        width: auto;

        &__tab {
          color: rgba($darkest-grey, 0.3);
          border-bottom: 1px solid $lightest-grey;
          flex: 1;
          align-items: center;
          display: flex;
          justify-content: center;
          cursor: pointer;
          transition: all 300ms ease-in-out;

          &--active {
            color: $darkest-grey;
            border-color: $darkest-grey;
            opacity: 1;
          }
        }
      }

      .modal-body .scrollable-content {
        margin: 0;
        padding: 16px;
      }
    }
  }

  &--tabbed--reviews {
    .mat-dialog-container {
      background-color: $background-grey;
    }

    .modal {
      .modal-body .scrollable-content {
        padding: 8px 16px;
      }
    }
  }

  &.generic--left .mat-dialog-container {
    padding: 40px 24px 24px !important;

    .modal {
      .modal-header,
      .modal-body p {
        text-align: left;
      }

      .modal-body {
        margin: 24px 0 16px;
      }
    }
  }
}

.modal-container--item-image-viewer {
  width: 100vw;
  max-width: none !important;
  height: 100vh;

  .mat-dialog-container {
    border-radius: 0;
    padding: 16px;
    max-width: none !important;
  }
}

/*====================
specific use-cases
====================*/
#items-in-offer-modal {
  padding: 16px 16px 24px !important;
  width: 342px;
}

#accept-offer-modal.offer-accepted-fullscreen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: none;
  border-radius: 0 !important;
  max-width: none;

  .modal {
    display: grid;
    grid-template-rows: auto auto 1fr;
    height: 100%;

    .item-image {
      margin-top: 60px;
    }
  }
}

#add-item-images {
  padding: 0 !important;
  overflow: visible;
}

// has to be fullscreen so users can't close it
#payment-3ds-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: none;
  border-radius: 0 !important;
  max-width: none;
}

.modal-with-tip .mat-dialog-container {
  overflow: visible;
}

.image-viewer-modal {
  display: block;
  width: 100vw;
  width: 100vw;
  max-width: none !important;
  height: 100vh;

  .mat-dialog-container {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    padding: 0 !important;
    width: 100vw;
    max-width: none !important;
    height: 100vh;
  }
}

.search-filters-modal {
  .mat-dialog-container {
    padding: 12px 0 0 !important;

    pc-close-icon {
      position: relative;
      top: 0;
      right: 0;
    }
  }
}

@include tablet {
  .modal-container {
    &--generic {
      .mat-dialog-container {
        padding: 40px 54px 24px !important;
        max-width: 472px;
      }
    }

    &--left,
    &--centered {
      position: static !important;
      min-width: 472px;
      max-width: 80vw !important;

      &.image-viewer-modal {
        max-width: none !important;
      }

      .mat-dialog-container {
        border-radius: 10px;
      }
    }

    &--left {
      &:not(.modal-with-tip) .modal-footer {
        display: flex;
        position: inherit;
        margin: 0;
        padding-top: 24px;
        padding-bottom: 0;
        border: 0;
        width: 100%;
      }

      .mat-dialog-container {
        padding: 40px !important;
      }
    }

    &--centered {
      .mat-dialog-container {
        padding: 40px 24px 0 !important;
        max-width: 524px;
      }

      .modal-header {
        margin: 0 -24px;
        padding: 0 24px 16px;
      }

      .modal-footer {
        margin: 0 -24px;
        padding: 24px;
      }
    }

    &--scrollable {
      height: 90% !important;
      max-height: 800px !important;
    }

    &--tabbed .modal .modal-header h1 {
      width: 700px;
    }

    &.generic--left .mat-dialog-container {
      padding: 40px !important;

      .modal .modal-body {
        margin-bottom: 32px;
      }
    }

    .modal-footer button {
      max-width: 324px;
    }
  }

  .mat-bottom-sheet-container {
    padding: 40px 24px !important;

    pc-close-icon {
      right: 32px;
    }
  }

  .search-filters-modal {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: none !important;

    .mat-dialog-container {
      border-radius: 0;
    }
  }

  #add-item-images,
  #forgotten-password-modal {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    max-width: none !important;
  }

  #forgotten-password-modal {
    padding: 80px !important;
  }

  .modal-with-tip .mat-dialog-container {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    max-width: none !important;
  }
}

@include desktop {
  .modal-container {
    .mat-dialog-container {
      max-width: 524px;
    }

    &--generic {
      .mat-dialog-container {
        padding: 40px 80px 24px !important;
      }
    }

    &--centered {
      // height: 90vh;
      height: auto;
      max-height: 740px;

      .mat-dialog-container {
        padding: 40px 40px 0 !important;
      }

      .modal-header {
        margin: 0 -40px;
        padding: 0 40px 16px;
      }

      .modal-footer {
        margin: 0 -40px;
        padding: 24px 40px 20px;
      }
    }

    &--left,
    &--centered {
      min-width: 524px;
    }

    &--tabbed {
      height: auto;
      min-height: 600px;
      max-height: 600px !important;
      position: static !important;
      width: 1048px;

      .mat-dialog-container {
        border-radius: 10px;
        max-width: none;
        background-color: $background-grey;
      }

      .modal {
        .modal-header,
        .modal-tabs {
          margin: 0;
        }

        .modal-header {
          height: 80px;

          h1 {
            width: 800px;
          }
        }

        .modal-tabs {
          height: 40px;
          width: inherit;
        }

        .modal-body {
          min-height: calc(600px - 80px - 40px);

          .scrollable-content {
            padding: 24px 100px !important;
          }
        }
      }
    }
  }

  .modal-with-tip.modal-container--left .modal-footer {
    display: flex;
    position: inherit;
    margin: 40px 0 -32px;
    border: 0;
    width: 100%;
  }

  #add-item-images,
  #forgotten-password-modal {
    position: static !important;
    position: relative !important;
    border-radius: 10px;
    padding: 40px 40px 24px !important;
    min-width: 472px;
    max-width: 80vw !important;
  }

  .modal-with-tip .mat-dialog-container {
    position: static !important;
    position: relative !important;
    border-radius: 10px;
    min-width: 472px;
    max-width: 80vw !important;
  }

  #modal-id-verification {
    max-width: 524px !important;
    min-height: 696px;

    .id-verification-modal {
      height: 585px;
    }
  }
}
