pc-small-item,
pc-small-user {
  width: 100%;
}

.small-data-container {
  display: flex;
  align-items: center;

  pc-menu {
    margin-left: 16px;
    height: 32px;
  }

  &.loading-data .small-data {
    background-color: transparent;

    &__thumbnail,
    &__detail__meta {
      background-color: $lightest-grey;
    }

    &__detail .highlight-text {
      margin-bottom: 8px;
      height: 1.6rem;
    }

    &__detail__meta {
      width: 80%;
      height: 1.6rem;
    }

    &__arrow {
      display: none;
    }
  }

  &--star-rating-bottom {
    flex-direction: column;
    align-items: flex-start;

    pc-user-star-rating {
      margin-top: 16px;
      margin-left: 48px;
    }
  }
}

.small-data {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100% !important;

  &:not(.small-data--link) {
    cursor: default;
    pointer-events: none;
  }

  &--large .small-data__thumbnail .sold-item-overlay .sold-tag {
    margin: 0 16px;
    height: 32px;
    line-height: 32px !important;
    font-size: 1.4rem;
  }

  &--link:hover .small-data__detail .highlight-text:not(.item-title__meta__delivery) {
    color: $primary;
  }
}

.small-data__thumbnail {
  position: relative;

  .sold-item-overlay .sold-tag {
    margin: 0 2px;
    height: 16px;
    line-height: 16px !important;
    font-size: 1rem;
  }
}

.small-data__detail {
  flex: 1;

  .highlight-text,
  &__meta {
    height: 2.1rem;
  }

  .highlight-text {
    transition: color 300ms ease-in-out;
    font-size: 1.6rem;
    // display: flex; -- removed to show whole price breakdown on small item
    align-items: center;

    .verified-badge {
      margin-left: 8px;
      width: 10px;
      height: 10px;
    }
  }

  &__meta {
    display: flex;
    align-items: center;

    p {
      height: 1.6rem;
      line-height: 1.6rem;
    }

    &__manually-sold {
      text-transform: none;

      .middot {
        margin: 0 4px;
      }
    }

    .meta-detail {
      margin-right: 8px;
    }

    .item-tag,
    .user-tag {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  &--no-meta-detail {
    .highlight-text,
    .small-data__detail__meta {
      height: auto;
    }

    .small-data__detail__meta .user-tag {
      margin-left: 0;
    }
  }
}

.small-data__arrow {
  display: flex;
  width: 10px;
}

.small-data--user {
  .small-data__thumbnail {
    border-radius: 50%;

    img {
      border-radius: inherit;
    }
  }
}

.small-data--user .small-data__detail__meta {
  height: auto;
  flex-wrap: wrap;

  .meta-detail {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 380px;
  }
}

.user-tag--following--wrapped,
.user-tag--student-roost-wrapped {
  margin-top: 8px;
}

.price-breakdown {
  text-transform: lowercase;
}