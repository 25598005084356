.coachmark-container {
  position: relative;
}

// arrow up is default layout & positioning
.coachmark {
  display: flex;
  position: absolute;
  top: 46px;
  left: -100px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  box-shadow: 0 3px 6px rgba($secondary, 0.2);
  background-color: $secondary;
  padding: 16px;
  width: 310px;
  text-align: center;

  &::before {
    position: absolute;
    bottom: 100%; // left: 80%;
    border: solid transparent;
    border-width: 11px;
    border-bottom-color: $secondary;
    width: 0;
    height: 0;
    content: ' ';
    pointer-events: none;
  }

  p {
    padding-right: 16px;
    line-height: 1.4rem;
    color: #fff;

    // font-family: $font-semi-bold;
    font-size: 1.4rem;
  }

  img {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }

  &--arrow-left {
    top: -60px;
    left: 10px;

    &::before {
      bottom: -21px;
      left: 28px;
      transform: rotate(180deg);
    }
  }
}
