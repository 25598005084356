// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');

/*
these styles aren't used but are set on the branch dashboard via
their css editor for the web-to-app journey banner
*/

#branch-banner-iframe {
  height: 60px;
}

#branch-banner {
  -webkit-text-size-adjust: 100%;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  z-index: 9999999;
  -webkit-font-smoothing: antialiased;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-transition: all 0.25s ease;
  transition: all 00.25s ease;
  position: fixed;
  left: 0;
  bottom: 0;
}

#branch-banner .branch-banner-content {
  height: 60px;
  color: #444141;
  background: white;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

#branch-banner .branch-banner-right {
  display: flex;
  align-items: center;
  margin: 0 8px;
}

#branch-banner .branch-banner-button {
  background-color: #f71d3b;
  height: 32px;
  line-height: 32px !important;
  border-radius: 20px;
  padding: 0 16px;
  width: auto;
  color: #fff;
  font-weight: 800;
  font-size: 12px;
  border: none;
  text-decoration: none;
  z-index: 2;
}

#branch-banner .branch-banner-left {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#branch-banner .branch-banner-close {
  padding: 6px;
  text-align: center;
  color: #444141;
  font-size: 24px;
  z-index: 2;
}

#branch-banner .branch-banner-icon {
  height: 42px;
  width: 42px;
  border-radius: 8px;
  padding: 0;
  margin: 0 8px 0 0;
}

#branch-banner .branch-banner-icon img {
  height: 42px;
  width: 42px;
  border-radius: 8px;
}

#branch-banner .branch-banner-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

#branch-banner .branch-banner-details .branch-banner-title {
  color: #444141;
  font-weight: 700;
  font-size: 12px;
}

#branch-banner .branch-banner-details .branch-banner-description {
  color: #444141;
  font-size: 10px;
  font-weight: 400;
}

#branch-banner .branch-banner-details .branch-banner-stars {
  display: none;
}

#branch-banner .branch-banner-details .branch-banner-reviews {
  display: none;
}
