.separator {
  margin: 12px 0;
  border-top: 1px solid $lightest-grey;

  &--full-width {
    @include e2e;
  }
}

.word-separator {
  display: flex;
  position: relative;
  align-items: center;

  span {
    border-top: 1px solid $light-grey;
    width: 100%;
  }

  p {
    background-color: #fff;
    padding: 0 16px;
    text-transform: lowercase;
    word-break: normal;
  }
}

// hide safari browser key icon on password inputs
input[type='password']::-webkit-contacts-auto-fill-button,
input[type='password']::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}
