.tip {
  display: grid;
  position: fixed;
  top: auto;
  right: 16px;
  bottom: 88px;
  left: 16px;
  grid-template-areas: 'icon tip-text';
  grid-template-columns: 40px auto 32px;
  transition: all 300ms ease-in-out;
  opacity: 1;
  border-radius: 8px;
  box-shadow: 0 3px 12px rgba($darkest-grey, 0.2);
  background-color: #fff;
  padding: 16px;
  width: auto;
  max-width: none;

  img {
    grid-area: icon;
  }

  h2 {
    display: none;
  }

  p {
    grid-area: tip-text;
  }

  pc-clear-input-btn {
    justify-self: flex-end;
  }

  &--closed {
    display: none;
  }

  &--hidden {
    opacity: 0;
  }
}

@include tablet {
  .tip {
    grid-template-columns: 48px auto 32px;
  }
}

@include desktop {
  .tip {
    grid-template-areas: 'icon title' 'tip-text tip-text';
    grid-template-rows: 48px auto;
    grid-template-columns: 48px auto;
    width: 100%;
    max-width: 308px;

    h2 {
      display: block;
      grid-area: title;
    }

    pc-clear-input-btn {
      display: none;
    }

    &--no-title {
      grid-template-areas: 'icon tip-text';
    }

    &--close-btn {
      grid-template-columns: 48px auto 32px;

      pc-clear-input-btn {
        display: block;
      }
    }
  }
}
