.input-valid {
  border-color: $green !important;
}

.input-invalid {
  border-color: $red !important;
}

// .ng-dirty {
//   .ng-valid {
//     border-color: $green !important;
//   }

//   .ng-invalid {
//     border-color: $red !important;
//   }
// }

.validation-error-message {
  margin: 8px 0;
  border: 1px solid $red;
  border-radius: 10px;
  box-shadow: 0 3px 12px rgba($darkest-grey, 0.2);
  background-color: rgba($red, 0.5);
  padding: 16px;

  p {
    color: #fff;
  }

  .password-length {
    display: block;
    margin-bottom: 10px;
  }
}

@include desktop {
  .validation-error-message {
    padding: 24px 40px;
  }
}
