/*====================
BREAKPOINTS
everything is written to be mobile-first
so we only need tablet and desktop breakpoints
to overwride or add to mobile styling
====================*/

@mixin x-small {
  @media (min-width: 0) and (max-width: 350px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 750px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}
