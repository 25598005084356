/*====================
typography
====================*/

:root {
  // typefaces
  --font-light: 'SofiaProLight', system-ui;
  --font-regular: 'SofiaProRegular', system-ui;
  --font-semi-bold: 'SofiaProSemiBold', system-ui;

  // headings
  --h1-font: 2.4rem / 2.8rem var(--font-semi-bold);
  --h2-font: 2.4rem / 2.8rem var(--font-semi-bold);
  --h3-font: 1.8rem / 2.8rem var(--font-semi-bold);
  --modal-left-heading: 3.6rem / 4rem var(--font-semi-bold);

  // body & paragraph
  --paragraph-large-font: 1.8rem / 2.6rem var(--font-regular);
  --paragraph-font: 1.4rem / 2.1rem var(--font-light);
  --paragraph-small-font: 1.4rem / 2.1rem var(--font-light);

  // sub text
  --sub-text-font: 1.2rem / 1.6rem var(--font-light);

  // feed specific
  --feed-item-name: 1.4rem / 1.7rem var(--font-regular);
  --feed-item-description: 1.2rem / 1.2rem var(--font-light);
  --feed-item-price: 1.2rem / 1.2rem var(--font-regular);

  // buttons
  --button-large-font: 1.6rem var(--font-regular);
  --button-font: 1.4rem var(--font-regular);
  --button-small-font: 1.2rem var(--font-regular);

  // tags
  --tag-big-font: 1.2rem / 1.4rem var(--font-semi-bold);
  --tag-font: 1rem / 1.4rem var(--font-semi-bold);

  // text links
  --text-link-font-family: var(--font-regular);
  --text-link-font-size: 1.6rem;
  --text-link-font-line-height: 2.4rem;
}

@include desktop {
  :root {
    // headings
    --h1-font: 2.8rem / 3.4rem var(--font-semi-bold);

    // body & paragraph
    --paragraph-font: 1.6rem / 2.4rem var(--font-light);

    // feed specific
    // --feed-item-name: 1.8rem / 2.4rem var(--font-regular);
    --feed-item-description: 1.6rem / 1.6rem var(--font-light);
    --feed-item-price: 1.6rem / 1.6rem var(--font-regular);
  }
}
