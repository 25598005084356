// class for filters that are buttons only
.buttons-filter {
  margin-bottom: -16px;

  p {
    margin-bottom: 16px;

    &:not(:first-of-type) {
      margin-top: 8px;
    }
  }

  button {
    margin: 0 16px 16px 0;

    img {
      width: 16px;
      height: 16px;
    }
  }

  pc-clear-input-btn {
    align-self: center;
    margin-left: 16px;

    button {
      margin: 0;
    }
  }

  &--block button {
    display: block;
  }
}
