@import 'colours';
@import 'breakpoints';

.mobile-settings {
  display: grid;
  grid-template-rows: 1fr 74px;
  margin-bottom: -24px;
  height: 100%;

  &__content {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  &__footer {
    border-top: 1px solid $lightest-grey;
    background-color: #fff;
    padding: 16px 0;
    height: 74px;
    text-align: right;
  }
}

@include tablet {
  .mobile-settings__footer .grid-container {
    padding-right: 80px;
    padding-left: 80px;
  }
}
